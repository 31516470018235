<template>
  <div class="row">
    <div class="col-12 col-md-11 col-lg-9 mx-auto">
      <div class="row g-5 mt-3">
        <div class="col-12" v-if="hasUnReviewed && !closedNotice">
          <div class="notice__box d-flex align-items-center justify-content-between mb-3 p-4">
            <div class="d-flex">
              <i class="bi bi-info-circle me-2"></i>
              <h5 class="fw-bold">有 {{numberOfPending}} 門課程待審核</h5>
            </div>
            <i class="bi bi-x-lg cursor__pointer" @click.prevent="closedNotice = true"></i>
          </div>
        </div>
        <div class="col-12 col-md-6 admin__box" v-if="role == 'Admin'">
          <div class="d-flex align-items-center mb-3">
            <div class="admin__box-icon me-4"><i class="bi bi-person-lines-fill"></i></div>
            <h3 class="fw-bold">使用者</h3>
          </div>
          <ul>
            <li><router-link class="dropdown-item d-flex py-2" to="/admin/users">
              <i class="bi bi-list-ul me-4"></i>
              <p>使用者列表</p>
            </router-link></li>
          </ul>
        </div>
        <div class="col-12 col-md-6 admin__box" v-if="role == 'Admin'">
          <div class="d-flex align-items-center mb-3">
            <div class="admin__box-icon me-4"><i class="bi bi-book me-1 icon-2"></i></div>
            <h3 class="fw-bold">課程</h3>
          </div>
          <ul>
            <li><router-link class="dropdown-item d-flex py-2"  to="/admin/courses">
              <i class="bi bi-list-ul me-4"></i>
              <p>課程列表</p>
            </router-link></li>
            <li v-if="role == 'Admin'"><router-link class="dropdown-item d-flex py-2"  to="/admin/categories">
              <i class="bi bi-list-ul me-4"></i>
              <p>課程分類列表</p>
            </router-link></li>
          </ul>
        </div>
        <div class="col-12 col-md-6 admin__box" v-if="role == 'Teacher'">
          <div class="d-flex align-items-center mb-3">
            <div class="admin__box-icon me-4"><i class="bi bi-book me-1 icon-2"></i></div>
            <h3 class="fw-bold">課程</h3>
          </div>
          <ul>
            <li><router-link class="dropdown-item d-flex py-2" to="/admin/t/courses">
              <i class="bi bi-list-ul me-4"></i>
              <p>課程列表</p>
            </router-link></li>
          </ul>
        </div>
        <div class="col-12 col-md-6 admin__box" v-if="role == 'Admin'">
          <div class="d-flex align-items-center mb-3">
            <div class="admin__box-icon me-4"><i class="bi bi-wallet-fill me-1 icon-3"></i></div>
            <h3 class="fw-bold">訂單</h3>
          </div>
          <ul>
            <li><router-link class="dropdown-item d-flex py-2" to="/admin/orders">
              <i class="bi bi-list-ul me-4"></i>
              <p>訂單列表</p>
            </router-link></li>
            <li v-if="role == 'Admin'"><router-link class="dropdown-item d-flex py-2"  to="/admin/profits">
              <i class="bi bi-list-ul me-4"></i>
              <p>通路設定</p>
            </router-link></li>
            <li><router-link class="dropdown-item d-flex py-2" to="/admin/report">
              <i class="bi bi-list-ul me-4"></i>
              <p>報表</p>
            </router-link></li>
          </ul>
        </div>
      </div>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Admin__Home',
  data() {
    return {
      metaTitle: '管理 - 元學堂',
      closedNotice: false,
      hasUnReviewed: false,
      numberOfPending: 0,
    };
  },
  components: {
  },
  metaInfo: {
    title: "管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    closeNotice() {
      
    },
    getData() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Admin/Dashboard`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.updateLoading(false);
        this.$log.debug(res.data.data.numberOfPending);
        this.numberOfPending = res.data.data.numberOfPending;
        if(res.data.data.numberOfPending == 0) {
          this.hasUnReviewed = false;
        } else {
          this.hasUnReviewed = true;
        }
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '權限不足',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userAuth', 'LoginProvider', 'role']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.role);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    } else if (this.role == 'Member') {
      this.$router.push('/');
    }
    if(this.role == 'Admin') {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
  .dropdown-item {
    &:hover {
      border-radius: 5px;
    }
    &:active {
      color: #1e2125;
      background-color: #e9ecef;
      border-radius: 5px;
    }
  }
  .cursor__pointer {
    cursor: pointer;
  }
</style>
